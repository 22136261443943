import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { EmailContactUsContent } from '../components/emailcontactus-content';
import { TiggbeeNoBackgroundContactUs } from '../components/tiggbee-no-background-contact-us';

const BusinessPage = () => (
    <Layout>
        <SEO title="Contact Us" />
        <EmailContactUsContent />
        <TiggbeeNoBackgroundContactUs
          titleElement={<>Together We Inspire Millions of Students</>}
          isSmallContainer={true}
          isHideButton={true}
        />
    </Layout>
)

export default BusinessPage
